exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-tsx-content-file-path-data-pages-about-index-mdx": () => import("./../../../src/templates/about.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/pages/about/index.mdx" /* webpackChunkName: "component---src-templates-about-tsx-content-file-path-data-pages-about-index-mdx" */),
  "component---src-templates-archive-index-tsx": () => import("./../../../src/templates/archive-index.tsx" /* webpackChunkName: "component---src-templates-archive-index-tsx" */),
  "component---src-templates-blog-index-tsx": () => import("./../../../src/templates/blog-index.tsx" /* webpackChunkName: "component---src-templates-blog-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-bash-cheatsheet-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/bash-cheatsheet/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-bash-cheatsheet-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-bind-first-configuration-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/bind-first-configuration/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-bind-first-configuration-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-hello-again-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/hello-again/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-hello-again-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-imagemagick-cmds-for-images-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/imagemagick-cmds-for-images/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-imagemagick-cmds-for-images-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-pytest-callback-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/pytest-callback/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-pytest-callback-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-sed-cheatsheet-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/sed-cheatsheet/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-sed-cheatsheet-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-sql-postgresql-pgadmin-compose-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/sql-postgresql-pgadmin-compose/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-sql-postgresql-pgadmin-compose-index-en-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-data-blog-sql-select-index-en-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/blog/sql-select/index.en.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-data-blog-sql-select-index-en-mdx" */),
  "component---src-templates-home-tsx-content-file-path-data-pages-home-index-en-mdx": () => import("./../../../src/templates/home.tsx?__contentFilePath=/home/runner/work/upr8.github.io/upr8.github.io/data/pages/home/index.en.mdx" /* webpackChunkName: "component---src-templates-home-tsx-content-file-path-data-pages-home-index-en-mdx" */),
  "component---src-templates-library-index-tsx": () => import("./../../../src/templates/library-index.tsx" /* webpackChunkName: "component---src-templates-library-index-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */)
}

